.App {
  text-align: center;
}

.btn {
  font-size: 1.3vw !important;
}

.navbar {
  display: block !important;
  text-align: right !important;
  background-color: #17a2b8 !important;
  border-bottom: 3px solid #17a2b8;
  color: white !important;
  padding: 0px !important;
}

.account-status {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  min-width: 100px;
}

.account-status-indicator {
  background-color: chartreuse;
  height: 16px;
  width: 16px;
  border-radius: 8px;
}
.close {
  font-size: 2.5rem !important;
  font-weight: bold !important;
}

.login-div {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  padding-top: 10%;
}

.connect-your-wallet {
  margin-top: 10%;
}

/* .wallet-details-count {
  margin-top: 5%;
} */

.genrate-wallet {
  margin-top: 15%;
}

.btn-login-page {
  width: 40%;
  padding-top: 3%;
  padding-bottom: 3%;
}

.btn-login-page-single {
  width: 25%;
  padding-top: 2%;
  padding-bottom: 2%;
}

.font-bold {
  font-weight: bold;
}

.font-size-onevw {
  font-size: 1.2vw;
}

.genrate-wallet-main {
  padding-top: 5%;
}

.wallet-details {
  margin-top: 5%;
  margin-left: 10%;
  margin-right: 10%;
  background-color: aqua;
  display: flex;
  align-items: center;
  justify-content: center;
}

.wallet-info {
  margin-top: 15%;
  margin-bottom: 15%;
}

.genrate-wallet-btns {
  margin-top: 2%;
}

.genrate-wallet-btn {
  width: 15%;
  padding-top: 1%;
  padding-bottom: 1%;
}

.main-data {
  margin-left: 10% !important;
  margin-right: 10% !important;
  padding-top: 5% !important;
}

.main-data-row {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}

.action-icon {
  size: 25px;
}

.ctatext {
  color: white !important;
}

.back-dark-color {
  background-color: #17a2b8 !important;
}

/* .card-header-border {
  border-bottom: 1px solid gray;
} */

.main-data-card {
  display: flex !important;
  justify-content: center !important;
  margin-top: 5%;
}

.btn-actions {
  background-color: transparent;
  border: none;
  /* width: 10%; */
}

.form-group-custom {
  margin-bottom: 0 !important;
}

.modal-from-body {
  padding-left: 7%;
  padding-right: 7%;
  padding-top: 2%;
  padding-bottom: 7%;
}

.btn-home-page {
  padding-bottom: 2%;
  padding-top: 2%;
}

.modal-lable {
  margin-top: 0.3rem;
}

.text-color {
  color: #17a2b8 !important;
}

.box-shadow-main {
  -webkit-box-shadow: 0 0 10px 5px rgb(230 230 239);
  -moz-box-shadow: 0 0 10px 5px rgb(230 230 239);
  box-shadow: 0 0 10px 5px rgb(230 230 239);
}
.balance-title {
  font-size: 20px;
  font-weight: 500;
}
.btn-lg {
  padding: 14px;
  font-weight: 600;
}
